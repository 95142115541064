import React from 'react';

import Layout from '../components/layout';
import Container from '../components/container';
import PageTitle from '../components/page-title';

interface SubmitInfoProps {
  location: {
    state: {
      title: string;
    };
  };
}

const SubmitInfo: React.FC<SubmitInfoProps> = ({location}) => {
  return (
    <Layout>
      <Container>
        <PageTitle title={location.state ? location.state.title : 'Виникла помилка. Будь ласка, спробуйте пізніше'} />
      </Container>
    </Layout>
  );
};

export default SubmitInfo;
